/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import window from 'global';
import * as React from 'react';
import MyButton from '../components/MyButton';
import MyCarousel from '../components/MyCarousel';
import MyCarouselSlide from '../components/CarouselSlide';
import DivideModuleCard from '../components/DivideModuleCard';
import Footer from '../components/Footer';
import Head from '../components/Head';
import HoverHighlightText from '../components/HoverHighlightText';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import MobileNavbar from '../components/MobileNav';
import ModuleCard from '../components/ModuleCard';
import Navbar from '../components/NavBar';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import UpButton from '../components/UpButton';
import pythonAdvanced from '../images/python-advanced.webp';
import pythonAlg from '../images/python-algorithm-and-data-structure.webp';
import pythonBE from '../images/python-back-end-web.webp';
import pythonBasics from '../images/python-basics.webp';
import pythonCourseBgImg from '../images/python-course-overview.webp';
import pythonPrice from '../images/python-course-price.webp';
import pythonData from '../images/python-data-analytics.webp';
import pythonFE from '../images/python-front-end-web.webp';
import pythonPrj from '../images/python-hands-on-project.webp';
import pythonBgImg from '../images/python-learn-coding.webp';
import pythonOverviewBgImg from '../images/python-overview.webp';
import pythonProgramming from '../images/python-programming.webp';
import pythonRobot from '../images/python-robotics.webp';
import pythonWeb from '../images/python-web-development.webp';
import * as divideModuleCardStyles from '../styles/divide-module-card.module.css';
import { hideCarousel } from '../utils';

const PythonPage = () => {
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Head pageTitle="Python Chief" />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      <Section backgroundImage={pythonBgImg} customStyle={{ textAlign: 'center' }}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="PYTHON CHIEF" style={{ fontSize: 'calc(3vw + 3vh)' }} />
            <br />
            <MainSubtitle text="BASIC PROGRAMMING SKILLS AND EXCEL DAILY TASKS" style={{ fontSize: 'calc(1.2vw + 1.2vh)' }} />
            <br />
            {/* <MainTitle text="COMING SOON" /> */}
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="PYTHON CHIEF" style={{ fontSize: 'calc(4vw + 4vh)' }} />
            <br />
            <MainSubtitle text="BASIC PROGRAMMING SKILLS AND EXCEL DAILY TASKS" style={{ fontSize: 'calc(2vw + 2vh)' }} />
            <br />
            {/* <MainTitle text="COMING SOON" style={{ fontSize: 'calc(4vw + 4vh)' }} /> */}
          </SectionContentContainer>
        )}
      </Section>
      {/* {dimension.width > breakpoint ? (
        <Section customStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5% 35%',
          }}
          >
            <ContactForm formTitle="INTERESTED? KEEP UPDATED HERE!" />
          </SectionContentContainer>
        </Section>
      ) : (
        <Section customStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5%',
          }}
          >
            <ContactForm formTitle="INTERESTED? KEEP UPDATED HERE!" />
          </SectionContentContainer>
        </Section>
      )} */}
      <Section backgroundImage={pythonOverviewBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4" customStyle={{ position: 'relative', padding: '40vh 0' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ABOUT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about Python programming basics and advanced"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn to use Python to develop different applications, ranging from frontend development to backend
                development"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Discover how Python is used in data analytics as well as robotics programming through
                hands on projects at class"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{ position: 'relative', padding: '20vh 0' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ABOUT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about Python programming basics and advanced"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn to use Python to develop different applications, ranging from frontend development to backend
                development"
              />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Discover how Python is used in data analytics as well as robotics programming through
                hands on projects at class"
              />
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <Section id="modules-container" customStyle={{ backgroundColor: 'black' }}>
        {dimension.width > breakpoint ? (
          <>
            <div style={{
              display: 'flex', justifyContent: 'center', position: 'absolute', top: '20%', width: '100%',
            }}
            >
              <DivideModuleCard moduleName="PYTHON PROGRAMMING" backgroundImage={pythonProgramming}>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${pythonBasics})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('pythonBasics').style.display = 'block';
                    document.getElementById('pythonBasics').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="PYTHON BASICS" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${pythonAdvanced})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('pythonAdvanced').style.display = 'block';
                    document.getElementById('pythonAdvanced').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="PYTHON ADVANCED" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
              </DivideModuleCard>
              <DivideModuleCard moduleName="WEB DEVELOPMENT" backgroundImage={pythonWeb}>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${pythonFE})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('pythonFE').style.display = 'block';
                    document.getElementById('pythonFE').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="FRONT END" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
                <div
                  className={`${divideModuleCardStyles.container} ${divideModuleCardStyles.divideChildren}`}
                  style={{
                    backgroundImage: `url(${pythonBE})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    margin: 0,
                  }}
                  onClick={() => {
                    hideCarousel();
                    document.getElementById('pythonBE').style.display = 'block';
                    document.getElementById('pythonBE').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  <MainTitle text="BACK END" className={divideModuleCardStyles.cardTitleFont} style={{ fontSize: 'calc(1.46vh + 1.46vw)' }} />
                  <br />
                  <button type="button" className={`${divideModuleCardStyles.btnContainer} ${divideModuleCardStyles.btnTitleFont}`} style={{ fontSize: 'calc(1vh + 1vw)' }}>
                    <span>LEARN MORE</span>
                  </button>
                </div>
              </DivideModuleCard>
            </div>
            <div style={{
              display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '20%', width: '100%', padding: '0 3vw',
            }}
            >
              <ModuleCard
                moduleName="ALGORITHM"
                backgroundImage={pythonAlg}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('pythonAlg').style.display = 'block';
                  document.getElementById('pythonAlg').scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <ModuleCard
                moduleName="DATA ANALYTICS"
                backgroundImage={pythonData}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('pythonData').style.display = 'block';
                  document.getElementById('pythonData').scrollIntoView({ behavior: 'smooth' });
                }}
              />
              <ModuleCard
                moduleName="ROBOTICS"
                backgroundImage={pythonRobot}
                onClick={() => {
                  hideCarousel();
                  document.getElementById('pythonRobot').style.display = 'block';
                  document.getElementById('pythonRobot').scrollIntoView({ behavior: 'smooth' });
                }}
              />
            </div>
          </>
        ) : (
          <>
            <ModuleCard
              moduleName="PYTHON BASICS"
              backgroundImage={pythonBasics}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonBasics').style.display = 'block';
                document.getElementById('pythonBasics').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="PYTHON ADVANCED"
              backgroundImage={pythonAdvanced}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonAdvanced').style.display = 'block';
                document.getElementById('pythonAdvanced').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="FRONT END"
              backgroundImage={pythonFE}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonFE').style.display = 'block';
                document.getElementById('pythonFE').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="BACK END"
              backgroundImage={pythonBE}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonBE').style.display = 'block';
                document.getElementById('pythonBE').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="ALGORITHM"
              backgroundImage={pythonAlg}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonAlg').style.display = 'block';
                document.getElementById('pythonAlg').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="DATA ANALYTICS"
              backgroundImage={pythonData}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonData').style.display = 'block';
                document.getElementById('pythonData').scrollIntoView({ behavior: 'smooth' });
              }}
            />
            <ModuleCard
              moduleName="ROBOTICS"
              backgroundImage={pythonRobot}
              onClick={() => {
                hideCarousel();
                document.getElementById('pythonRobot').style.display = 'block';
                document.getElementById('pythonRobot').scrollIntoView({ behavior: 'smooth' });
              }}
            />
          </>
        )}
      </Section>

      {/*
      *
      * PYTHON BASICS CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonBasics"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonBasics}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="PYTHON BASICS" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Strong foundation in programming" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Python Intro" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="String manipulation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Conditional Statements" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Sequential Collections" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Iterations" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Non-sequential Collections" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Modularity" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Application Building" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonPrj}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Create an interactive application through command line" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%' }}>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Create an interactive application through command line" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonPrice}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                  <MainTitle text="PRICING" />
                  <br />
                  <br />
                  <div className="row justify-content-around" style={{ width: '70%', margin: 'auto', marginBottom: '3vh' }}>
                    <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'center' }}>
                      <MainTitle text="OUR PRICES" style={{ marginBottom: '3vh' }} />
                      <HoverHighlightText highlightText="$70" normalText="per hour" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <HoverHighlightText highlightText="15" normalText="lessons" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <MainTitle text="$2100" />
                    </div>
                    <div className="col-6" style={{ textAlign: 'center' }}>
                      <MainTitle text="OTHERS' CHARGE" style={{ marginBottom: '3vh' }} />
                      <HoverHighlightText highlightText="$60" normalText="per hour" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <HoverHighlightText highlightText="30" normalText="lessons" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <MainTitle text="$3600" />
                    </div>
                  </div>
                  <MainTitle text="FASTER AT BETTER VALUE" />
                  <br />
                  <MyButton title="CONTACT US" link="/contact" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                  <MainTitle text="PRICING" style={{ fontSize: 'calc(3vh + 3vw)' }} />
                  <br />
                  <br />
                  <div className="row justify-content-around" style={{ width: '84%', margin: 'auto', marginBottom: '3vh' }}>
                    <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'center' }}>
                      <MainTitle text="OUR PRICES" style={{ marginBottom: '3vh', fontSize: 'calc(2vh + 2vw)' }} />
                      <HoverHighlightText highlightText="$70" normalText="per hour" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <HoverHighlightText highlightText="15" normalText="lessons" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <MainTitle text="$2100" style={{ fontSize: 'calc(3vh + 3vw)' }} />
                    </div>
                    <div className="col-6" style={{ textAlign: 'center' }}>
                      <MainTitle text="OTHERS' CHARGE" style={{ marginBottom: '3vh', fontSize: 'calc(2vh + 2vw)' }} />
                      <HoverHighlightText highlightText="$60" normalText="per hour" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <HoverHighlightText highlightText="30" normalText="lessons" style={{ marginBottom: '3vh', justifyContent: 'center' }} />
                      <MainTitle text="$3600" style={{ fontSize: 'calc(3vh + 3vw)' }} />
                    </div>
                  </div>
                  <MainTitle text="FASTER AT BETTER VALUE" style={{ fontSize: 'calc(3vh + 3vw)' }} />
                  <br />
                  <MyButton title="CONTACT US" link="/contact" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * PYTHON ADVANCED CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonAdvanced"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonAdvanced}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="PYTHON ADVANCED" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="More advanced concepts" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Shorthand Statement & Lambda" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="File Processing" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Memory Allocation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Class and Objects" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Abstraction and Encapsulation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Inheritance" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Polymorphism" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Testing & Debugging" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * FRONT END CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonFE"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonFE}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="FRONT END" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Prototype building, Landing page, dynamic website" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="HTML & CSS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Advanced CSS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Bootstrap" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Javascript" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Advanced JS & Typescript" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="React Basics" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="React Functional Components" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="React Router & Redux" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Styling & Ant Design" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="API Integration" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonPrj}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Build a static web page to display your bio" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%' }}>
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainTitle text="HANDS-ON PROJECT" />
                  </div>
                  <br />
                  <div data-aos="fade-down" data-aos-duration="500">
                    <MainSubtitle text="Create an interactive application through command line" />
                  </div>
                  <br />
                  <MainTitle text="Practical skills that we are giving:" style={{ fontSize: 'calc(1.8vw + 1.8vh)' }} />
                  <MainSubtitle text="- Skills" />
                  <MainSubtitle text="- Project" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * PYTHON BACK END CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonBE"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonBE}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="BACK END" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Strong backend web services" />
                  <MainSubtitle text="Develop RESTful API" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW COURSE I" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="SQL Introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="SQL fundamentals" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="CRUD Tables Operation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="SQL Advanced" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="AWS Introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Deploy SQL Server on AWS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="NoSQL & its usage" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW COURSE II" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Introduction to Flask" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Monolithic App with Flask" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Microservices & REST API" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Build API with Flask" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Unit testing" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Security in REST API" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Deploy application on AWS" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * PYTHON ALGORITHM AND DATA STRUCTURE CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonAlg"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonAlg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="ALGORITHM AND DATA STRUCTURE" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Optimize code" />
                  <MainSubtitle text="Ace the coding interview" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Complexity" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Bubble & Insertion sort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Quicksort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Merge sort" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Linear & Binary search" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Hash table" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Stack & Queue" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Linked list" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="BST & traversal" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * PYTHON DATA ANALYTICS CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonData"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonData}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="DATA ANALYTICS" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Visualization, analytics and prediction based on historical data" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="Data basics" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Data Collection" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Data Processing" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Data Analysis" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Data visualization" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Data Modeling" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />

      {/*
      *
      * PYTHON ROBOTICS CAROUSEL
      *
      */}

      <MyCarousel
        style={{ display: 'none' }}
        carouselID="pythonRobot"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={pythonRobot}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="ROBOTICS" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Create a robot of your own" />
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonCourseBgImg}>
              {dimension.width > breakpoint ? (
                <SectionContentContainer customStyle={{ top: '30%', left: '15%' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              ) : (
                <SectionContentContainer customStyle={{ top: '30%', left: '0' }}>
                  <HoverHighlightText highlightText="12" normalText="lessons" />
                  <HoverHighlightText highlightText="2" normalText="hours per lesson" />
                  <HoverHighlightText highlightText="1:3" normalText="max instructor to students ratio" />
                </SectionContentContainer>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section customStyle={{ backgroundColor: 'black' }}>
              <SectionContentContainer customStyle={{ top: '10%', left: 0, textAlign: 'center' }}>
                <MainTitle text="CONTENT OVERVIEW" />
                <br />
                <br />
                <br />
                <br />
                <div className="row justify-content-around" style={{ width: '70%', margin: 'auto' }}>
                  <div className="col-6" style={{ borderRight: '1px solid white', textAlign: 'left' }}>
                    <MainTitle text="ROS Introduction" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="ROS Framework" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="ROS Simulation and Visualization" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="ROS in Python" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Robotics simulation" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                  <div className="col-6" style={{ textAlign: 'right' }}>
                    <MainTitle text="Robotics design" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="ROS & RPA" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Hands-on Project" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                    <MainTitle text="Final Exam" style={{ marginBottom: '5vh', fontSize: 'calc(1.2vh + 1.2vw)' }} />
                  </div>
                </div>
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
        ]}
      />
      <UpButton />
      <Footer />
    </>
  );
};

export default PythonPage;
